export default {
    translations: { // Mesmo valor usado nas configurações (defaultNS)
      home: {
        message: 'Olá Mundo!'
      },
      sankey: {
        sem_dados: 'Sem dados disponíveis',
        col1tab1: 'Cidade Hub por Proporção de Caminho',
        col2tab1: 'Estado da Cidade Hub',
        col3tab1: 'Código IBGE da Cidade Hub',
        col4tab1: 'Proporção de Caminhos que Levam ao Hub',
        col1tab2: 'Cidade de Entrada',
        col2tab2: 'Código IBGE da Cidade de Entrada',
        col3tab2: 'Cidade Afetada',
        col4tab2: 'Unidade Federativa da Cidade Afetada',
        col5tab2: 'Código IBGE da Cidade Afetada',
        col6tab2: 'Proporção de Caminhos que Levam às Cidades Afetadas',
        col1tab3: 'Cidades Sugeridas para Detecção Precoce de Patógenos na Cidade Selecionada',
        col2tab3: 'Estado da Cidade Hub',
        col3tab3: 'Código IBGE da Cidade Hub',
        col4tab3: 'Proporção de Caminhos que Levam ao Hub',
      },
      dashboard: {
        //title: 'Monitoramento das Infecções Respiratórias Atenção Primária em Saúde (APS) no Brasil',
        //subtitle: 'Visão Geral dos Atendimentos por Infecções de Via Aérea Superior (Gripais)',
        a: 'a',
        title: 'Monitoramento dos Atendimentos por Arboviroses na Atenção Primária em Saúde (APS) no Brasil',
        subtitle: '',
        queda: 'Queda',
        estavel: 'Estável',
        alerta: 'Atenção',
        alta : 'Alta',
        data : 'Data',
        buscar_uf: 'Filtrar UF',
        buscar_municipio: 'Filtrar Municipio',
        cidade : 'Município',
        estado : 'Estado',
        populacao: 'População',
        atencao: 'Atenção',
        aviso: 'Aviso',
        semana_ep: 'Semana Epidemiológica',
        tosse:'Tosse',
        febre:'Febre',
        dqi: 'DQI',
        ears: 'EARS',
        evi_simples: 'EVI',
        ambos: 'Ambos',
        sem_aviso: 'Sem Aviso',
        apto: 'Validado',
        nao_apto: 'Não Validado',
        no_data: 'Sem Dados',
        probabilidade_crescimento: 'Probabilidade de Crescimento',
        aviso_consecutivo: 'Aviso Consecutivo',
        sem_informacao: 'Sem Informação',
        titulo_mapa_taxa_crescimento: 'Taxa de Crescimento de Provável Arbovirose',
        titulo_mapa_atendimentos_excedentes : 'Provável Arbovirose Excedente',
        titulo_mapa_evi: 'Sinal de Aviso',
        evi: 'EVI (Índice de Volatilidade Epidêmica)',
        legenda_mapa_taxa_crescimento: 'Taxa de Crescimento (%)',
        legenda_mapa_atendimentos_excedentes: 'Atendimentos Excedentes',
        perc_completude: 'Completude (%)',
        perc_tempestividade: 'Tempestividade (%)',
        retangulos:{
          titulo_excesso : 'Excesso de Provável Arbovirose',
          mil: 'K',
          milhoes: 'M',
          bilhoes: 'G',
        },
        dados_climaticos: 'Dados Climáticos',
        graficos: {
          titulo_mm: 'Proporção de Provável Arbovirose',
          legenda_mm: {
            1: 'Média Móvel',
            2: 'Proporção (Provável Arbovirose / Total de atendimentos)'
          },
          titulo_variacao_mm : 'Taxa de Crescimento da Proporção de Provável Arbovirose (Média Móvel)',
          titulo_atendimento_gripais : 'Provável Arbovirose na APS',
          titulo_atendimento_totais : 'Total de Atendimentos na APS',
          titulo_excesso : 'Excesso de Provável Arbovirose do Limite Esperado',
          titulo_limite : 'Limite Esperado de Provável Arbovirose',
          titulo_precipitacao : 'Precipitação',
          titulo_humidade : 'Umidade',
          titulo_indice_calor :'Índice de Calor',
          titulo_temp2m :'Temperatura a 2 Metros',
          titulo_med_movel_precipitacao : 'Média Móvel Precipitação',
          titulo_med_movel_humidade : 'Média Móvel Umidade',
          titulo_med_movel_indice_calor :'Média Móvel Índice de Calor',
          titulo_med_movel_temp2m :'Média Móvel Temperatura a 2 Metros',
          titulo_tosse_febre: 'Tosse e Febre',
        },
        table: {
          titulo: 'Consulta dos Indicadores nos Municípios',
          titulo_estados: 'Consulta dos Indicadores nos Municípios',
          col1: 'Município​',
          col2: 'População',
          col3: 'Taxa de Crescimento (Proporção Arboviroses) %',
          col9: 'EVI (Índice de Volatilidade Epidêmica)',
          col4: 'Excesso de Atendimentos (Arboviroses)',
          col5: 'Limite Superior Esperado de Atendimentos (Arboviroses)',
          col6: 'Proporção de Provável Arbovirose',
          col7: 'Provável Arbovirose',
          col8: 'Atendimentos Totais',
          estado: 'UF',
          limite: 'Limite',
          limite_glm: 'Limite GLM',
          excesso: 'Excesso',
          excesso_glm: 'Excesso GLM',
          evi: 'EVI',
          ears: 'Ears',
          sinal_evi: 'Sinal EVI Consecutivo',
          sinal_ears: 'Sinal Ears Consecutivo',
          sinal_glm: 'Sinal GLM Consecutivo',
          sinal_ens: 'Sinal Ensemble Consecutivo',
          excesso_ens: 'Excesso Ensemble',
          limite_ens: 'Limite Ensemble',
          sinais: 'Total de Sinais',
        },
        todos: 'Todos',
        mensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Não foi possível calcular taxa de crescimento',
        subMensagemNaoFoiPossivelCalcularTaxaCrescimento: 'Indef',
        mesAno: 'Mês/Ano: ',
        flowMapNomeLayerMob: 'Rotas de Mobilidade',
        semanal: 'Semanal',
        mensal: 'Mensal',
        excesso: 'Excesso de Provável Arbovirose do Limite Esperado',
        excesso_simples: 'Excesso',
        gripe: 'Gripe',
        arbo: 'Arbo',
      }
    }
  }