import { createGlobalStyle} from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    /*background-image: url('imagens/bg-boletim.jpg');
    background-attachment: initial;
    background-position: 50% 0%;
    background-size: 50%;
    background-repeat: repeat-x;*/
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }

  .change_color_text .MuiAutocomplete-input {
    color: ${({ theme }) => theme.text};  
  }
  
 /* .change_color_text .MuiAutocomplete-option {
    color: ${({ theme }) => theme.text};  
  }*/

 .imagem_bg{
    background-image: url(${({ theme }) => theme.background_image});
    background-attachment: initial;
    background-position: 50% 0%;
    background-repeat: repeat-x;
    min-width: 100%;
  }

  .bg{
    background-color: ${({ theme }) => theme.body};
    min-width: 100%;
  }


/*.leaflet-control-layers-overlays span{
    color: ${({ theme }) => theme.text};
} */

#dtBasicExample tr th a {
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    text-align: center;
    vertical-align: middle;
  }
  
#dtBasicExample tr td a {
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    text-align: center;
    vertical-align: middle;
}
  
#dtBasicExample thead tr th a {
    display: flex;
    text-decoration: none;
    color: ${({ theme }) => theme.text};
    text-align: center;
    vertical-align: middle;
}
  
#dtBasicExample tr td {
    color: ${({ theme }) => theme.text};
}
  
#dtBasicExample tr th {
    color: ${({ theme }) => theme.text};
}

ul li a {
    color: ${({ theme }) =>theme.a};
    text-decoration: none;
}

ul li a:hover {
    color: ${({ theme }) =>theme.a_hover};
    text-decoration: none;
}

a.nav-link {
    color: ${({ theme }) =>theme.a};
    text-decoration: none;
}

.active{
    color: 'green';
}

a.nav-link.active:hover {
    color: ${({ theme }) =>theme.a_hover};
    text-decoration: none;
}

a.nav-link:link {
    
    color: ${({ theme }) => theme.a};
    text-decoration: none;

}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: gray !important;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
    color: darkgray !important;
}

.navbar-toggler {
    filter: invert(${({ theme }) => theme.toggler_icon});
}
.selected-lang{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    line-height: 2;
    width: 25px;
    color: ${({ theme }) => theme.a};
}
.txt_head_small{
    font-size: 0.95vw;
    font-weight: 600;
    color: ${({ theme }) => theme.a};
    text-shadow: 0 0 0.45em ${({ theme }) => theme.body};
    /*text-shadow: ${({ theme }) => theme.body} 0.1em 0.1em 0.2em;*/
}
.lang-menu ul {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.body};
    border: 1px solid gray;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: none;
    position: absolute;
    top: 20px;
    left: -80px;
}

.lang-menu ul li a:hover{
    min-width: 85px;
    background-color: #535353;
    color: ${({ theme }) => theme.a_text_hover};
}

.aesop_logo{
    filter: invert(${({ theme }) => theme.aesop_logo});
}
.img-fiocruz{
    /*height: 9.5vh;*/
    filter: invert(${({ theme }) => theme.aesop_logo});
}
.img-rockfeller{
    filter: invert(${({ theme }) => theme.rockefeller_logo});
}

.img-coppe{
    filter: invert(${({ theme }) => theme.coppe_logo});
}

.subtitulo {
    color: ${({ theme }) => theme.text};
    text-shadow: ${({ theme }) => theme.body} 0.1em 0.1em 0.2em;
}

.sub-titulo {
    color: ${({ theme }) => theme.text};
    text-shadow: ${({ theme }) => theme.body} 0.1em 0.1em 0.2em;
}

.cor_diferenciada{
    color: ${({ theme }) => theme.cor_diferenciada}; 
}
.sombra_diferenciada{
    color: #75ffc0;
    text-shadow: Black 0.1em 0.1em 0.2em;
}

#custom-switch:checked{
    color:#fff;
    background-color:#000;
}

a.list-group-item.list-group-item-action{
    color:#000;
    text-shadow: none;
    text-decoration: none;
}

.layer_descricao{
    background: ${({ theme }) => theme.cor_nome_layer};
}

.navbar {
    background-color: rgba(${({ theme }) => theme.fundo_navbar});
}

.legend{
    color: ${({ theme }) => theme.legenda_graficos};
}

.legend table, th, td, tr {
    color: ${({ theme }) => theme.legenda_graficos};
}

.grafico{
    background-color: ${({ theme }) => theme.background_grafico};
} 

.btn-close{
    background-color: ${({ theme }) => theme.text};
}

/*button.button_maximize.btn.btn-primary{
    background-color: ${({ theme }) => theme.background_grafico};
    background-image: url(${({ theme }) => theme.background_maximize});
    border-color: ${({ theme }) => theme.background_grafico};
    background-size: 22px 22px;
    background-position: 50% 50%;
    background-repeat: none;
    z-index:10;
}*/

button.button_maximize{
    background-color: ${({ theme }) => theme.background_grafico};
    background-image: url(${({ theme }) => theme.background_maximize});
    border-color: ${({ theme }) => theme.background_grafico};
    background-size: 22px 22px;
    background-position: 50% 50%;
    background-repeat: none;
    z-index:10;
}

.modal-content{
    color: ${({ theme }) => theme.text};
    background-color: ${({ theme }) => theme.background_grafico};
}

.titulo_tabela{
    color: ${({ theme }) => theme.text};
}

.container_retangulo{
    color: ${({ theme }) => theme.texto_retangulo};
    background-color: ${({ theme }) => theme.fundo_retangulo};
}

.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  
.MuiInputLabel-root {
    color: ${({ theme }) => theme.text} !important;
} 

.MuiSvgIcon-root {
    fill: ${({ theme }) => theme.text} !important;
}

#demo-simple-select{
    color: ${({ theme }) => theme.text};
  }

.Mui-focused .MuiOutlinedInput-notchedOutline {
border-color: #545453 !important;
}

@media screen and (max-width: 995px) {
    .img-fiocruz{
        width: 30px;
        height: 45px;
        filter: invert(${({ theme }) => theme.aesop_logo});
    }

    .form-switch{
        text-align: center;
    }

    .sub-titulo{
        max-width: 0px;
    }
}
  `


